.l-site-footer,
.hero {
  max-width: unset;
}

@media (min-width: 90.0625em) {
  .hero {
    padding: 50px 0;
  }

  .l-site-footer__copyright {
    margin-top: 100px;
  }

}

.l-site-content {
  max-width: unset;
}

.l-site-header__inner {
  max-width: unset;
  width: 100%;
}

/* section padding  */
.l-team.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;

}

.l-site-footer__inner {}

/* Footer */
.footer {
  display: flex;
  flex-wrap: wrap;

  margin: auto;
  max-width: 95%;
  width: 1600px;
  padding-left: 20px;
  padding-right: 20px;
}

.footer .Footer-row {
  width: 25%;
}

.l-site-footer__copyright {
  display: flex;
  justify-content: center;
}

.Footer-header {
  font-size: 20px;
  margin-bottom: 10px;
}

.Footer-txt {
  font-size: 16px;
}

.social-links {
  display: flex;
  flex-wrap: wrap;
}


/* Product Link  */
.blurb__body {
  margin-bottom: 20px;
}

.l-main-w-sidebar.contact-us {
  margin-top: 0;
}

/* @media (min-width: 90.0625em) */


@media (min-width: 90.0625em) {
  .l-site-footer__copyright {
    margin-top: 0px;
  }

  .cta-large__pattern {
    width: 50%;
    opacity: 1;
  }
}

@media (min-width: 57.5em) {
  .l-site-footer {
    padding: 50px 0 50px;
    height: auto;
    position: relative;
    bottom: unset;
  }

  .l-site-content {
    margin-bottom: 0px;
  }

}

.team-management-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.sub-title {
  font-size: 40px;
  margin-bottom: 25px;
  margin-left: 15px;
}


.team-management-wrapper .team-management-block {
  width: calc(100%);
  /* margin-left: 15px;
margin-right: 15px; */
  margin-bottom: 30px;
}

.team-management-wrapper .team-member-img {
  margin-bottom: 15px;
}

.team-management-wrapper .team-member-img img {
  width: 100%;
  object-fit: cover;
  object-fit: cover;
}

@media (min-width: 750px) {
  .team-management-wrapper .team-management-block {
    width: calc(50% - 30px);
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media (min-width: 1200px) {
  .team-management-wrapper .team-management-block {
    width: calc(22.33% - 30px)
  }


}

.location__fax:before,
.location__phone:before {
  color: #e4082f;
}

.eyebrow-xl {
  color: #e4082f;
}

.form .freeform-row .freeform-column button[type="submit"],
.btn {
  background-color: #e4082f;
  color: #fff;
}

@media (min-width: 42.5em) {
  body:not(.is-touch-device) .nav__link:hover {
    color: #e4082f;
  }
}


@media (max-width: 42.49em) {
  .nav__right.active {
    display: block;
  }

  .nav__open {
    width: auto;
  }

  .nav__right {
    width: 100%;
  }

  .nav__links {
    width: 100%;
    background-color: #f5f0eb;
    min-height: auto;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
}

.nav {
  justify-content: space-between;
}

.nav__logo {
  width: 180px;
}
/*.team-member-img {*/
/*  height: 320px;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  background-position: center top;*/
/*}*/

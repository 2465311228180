.bread-crumbs-wrrapper {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 10;

}

.bread-crumbs-wrrapper::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    content: '';
    z-index: -1;

}



.bread-crumbs-title {
    font-size: 55px;
    margin-bottom: 16px;
}